import Head from 'next/head'
import { useRouter } from 'next/router'

import metaImage from '@/assets/meta_image.jpg'
import { GenericPageProps } from '@/types/page'

type ExplicitProps = {
  title: string
  description: string
  keywords: string
  imageUrl?: string
  noindex?: boolean
}

export const ExplicitHead = ({ title, description, keywords, imageUrl, noindex }: ExplicitProps) => {
  const router = useRouter()

  title = title.includes('EDHREC') ? title : `${title} | EDHREC`
  const image = imageUrl ? imageUrl : metaImage.src
  const url = `https://edhrec.com${router.asPath === '/' ? '' : router.asPath}`

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='title' content={title} />
        <meta property='og:title' content={title} />
        <meta name='description' content={description} />
        <meta property='og:description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta property='og:keywords' content={keywords} />
        {noindex ? (
          <meta name='robots' content='noindex' />
        ) : (
          <>
            <link rel='canonical' href={url} />
            <meta property='og:url' content={url} />
          </>
        )}
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='EDHREC' />
        <meta property='og:image' content={image} />
        <meta name='twitter:card' content='summary_small_image' />
        <meta name='twitter:image:alt' content='EDHREC' />
      </Head>
    </>
  )
}

type Props = {
  data: GenericPageProps['data']
}

const DefaultHead = ({ data }: Props) => {
  return (
    <ExplicitHead
      title={data.container.title}
      description={data.container.description}
      keywords={data.container.keywords}
      imageUrl={data.container?.json_dict?.card?.image_uris[0]?.art_crop}
    />
  )
}

export default DefaultHead
