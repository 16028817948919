import { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'

import Ad from '@/components/Ad/Ad'
import useDirectories from '@/utils/useDirectories'
import usePatreonTier from '@/utils/usePatreonTier'

import styles from './Leaderboard.module.scss'

const Leaderboard = () => {
  const patreonTier = usePatreonTier()
  const { query } = useDirectories()
  const [randomRoll, setRandomRoll] = useState<number>(0)

  useEffect(() => {
    setRandomRoll(Math.random())
    const interval = setInterval(() => {
      setRandomRoll(Math.random())
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  if (patreonTier && !query?.ads) return null

  /* Ad Setup:
     - 20.0% Command Zone
     - 10.0% SCG
     - 10.0% CardZilla (until Dec 1, 2024)
     - 10.0% Dragon Shield (until Sept 1, 2024)

     - Half of remainder split between SCM
     - Other half of remainder goes to EDHREC Patreon
  */

  const ad =
    randomRoll === 0 ? null : randomRoll < 0.2 ? (
      <Ad id='commandzone' />
    ) : randomRoll < 0.25 ? (
      <Ad id='scg1' />
    ) : randomRoll < 0.3 ? (
      <Ad id='scg2' />
    ) : randomRoll < 0.4 ? (
      <Ad id='dragonShield' />
    ) : randomRoll < 0.4 + 0.6 * (1 / 14) ? (
      <Ad id='archidekt' />
    ) : randomRoll < 0.4 + 0.6 * (2 / 14) ? (
      <Ad id='ch' />
    ) : randomRoll < 0.4 + 0.6 * (3 / 14) ? (
      <Ad id='csb' />
    ) : randomRoll < 0.4 + 0.6 * (4 / 14) ? (
      <Ad id='edhrecast' />
    ) : randomRoll < 0.4 + 0.6 * (5 / 14) ? (
      <Ad id='edhrecap' />
    ) : randomRoll < 0.4 + 0.6 * (5.25 / 14) ? (
      <Ad id='spellifyDark1' />
    ) : randomRoll < 0.4 + 0.6 * (5.5 / 14) ? (
      <Ad id='spellifyDark2' />
    ) : randomRoll < 0.4 + 0.6 * (5.75 / 14) ? (
      <Ad id='spellifyLight1' />
    ) : randomRoll < 0.4 + 0.6 * (6 / 14) ? (
      <Ad id='spellifyLight2' />
    ) : randomRoll < 0.4 + 0.6 * (7 / 14) ? (
      <Ad id='fabrec' />
    ) : (
      <Ad id='edhrecPatreon' />
    )

  return (
    <div className={styles.container}>
      <div className={styles.leaderboard}>
        <LazyLoad>{ad}</LazyLoad>
      </div>
    </div>
  )
}

export default Leaderboard
